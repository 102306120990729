
// Libraries
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { ButtonText } from "~/operations/messages";

// Components
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import { getLogoUrl } from "~/operations/shared";
@Component({
  components: {
    RouterLink,
  },
})
export default class Header extends Vue {
  @Prop() showNavigateBackwardsButton: boolean;
  @Prop() showGotoShopLink: boolean;

  ButtonText = ButtonText;

  resetCheckoutStatusAndNavigate() {
    this.$store.dispatch("checkout/resetCheckoutStatus");
    this.$router.go(-1)
  }

  goToComparison() {
    
    const filterJSON = localStorage.getItem("filter-params")
    const query = JSON.parse(filterJSON)

    this.$router.push({
        path: "/bestellung",
        query
    });  
  }

  get _getLogoUrl() {
    return getLogoUrl();
  }

  get colorClass() {
    // eslint-disable-next-line no-undef
    return process.env.MAIN_ESYOIL.includes(".com") ? "" : "io-blue";
  }
}
